import { mock, Random } from 'mockjs';

const gernerate = (i: number) => {
  let month = Random.date('MM');
  return mock({
    c1: `API-TX0021200${i}`,
    c2: `${month}结算单模板文件接口`,
    c3: `${month}结算单模板`,
    'c4|1': ['已定义', '未定义'],
    'c5|1': ['已定义', '未定义'],
    c6: '2021-11-30'
  });
};

let d = [];
for (let i = 100; i < 111; i++) {
  d.push(gernerate(i));
}

export const list = d;

export const columns = [
  { title: '接口编码', dataIndex: 'c1', width: 180 },
  { title: '接口名称', dataIndex: 'c2', width: 180 },
  { title: '结算单模板名称', dataIndex: 'c3', width: 120 },
  { title: '数据文件接口', dataIndex: 'c4', width: 100 },
  { title: '报销文件接口', dataIndex: 'c5', width: 100 },
  { title: '创建日期', dataIndex: 'c6', width: 200 },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    width: 130,
    scopedSlots: {
      customRender: 'action'
    }
  }
];
